@import '../config';

.box {
  border-radius: 7px;
  border: 1px solid gray('gray-150');
  background-color: color('white');
  font-size: 14px;
  //overflow: hidden;
  margin-bottom: 30px;

  &--nomargin {
    margin-bottom: 0;
  }

  & > :last-child {
    border-bottom: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray('gray-150');
    padding: 25px;
    position: relative;

    h2.box__header-title {
      //prevent some fuck from box refactor
      margin-top: 0;
      margin-bottom: 0;
      font-size: 22px;
      font-weight: 500; //font-weight: 400;;
      display: block;
      min-height: 33px;

      &--green {
        position: relative;

        &:before {
          content: '';
          background-color: theme-color('primary');
          position: absolute;
          left: -26px;
          top: 0;
          width: 5px;
          height: 100%;
        }
      }

      .btn {
        display: inline-block;
        margin-left: 10px;
        vertical-align: text-bottom;
      }
    }

    &-smallnote {
      font-family: $font-family-base;
      font-weight: $font-weight-normal;
      font-size: 12px;
      line-height: 1.25em;
      display: block;
      color: gray('gray-400');
    }

    &--icon {
      &:before {
        content: '';
        position: absolute;
        height: 20px;
        width: 20px;
        top: -10px;
        left: 25px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }

  &__body {
    border-bottom: 1px solid gray('gray-150');
    padding: 25px;
    & > :first-child {
      margin-top: 0;
    }
    & > :last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    border-bottom: 1px solid gray('gray-150');
    padding: 25px;
    background-color: gray('gray-100');

    & > :last-child {
      margin-bottom: 0;
    }

    &--info {
      padding-left: 45px;
      position: relative;

      &:before {
        content: '?';
        font-size: 11px;
        display: block;
        width: 13px;
        height: 13px;
        line-height: 14px;
        text-align: center;
        position: absolute;
        top: 28px;
        left: 25px;
        background-color: gray('gray-400');
        border-radius: 100%;
        color: gray('white');
        font-weight: 400; //font-weight: 400;;
        overflow: hidden;
      }
    }

    &--alert {
      padding-left: 45px;
      position: relative;

      &:before {
        content: '!';
        font-size: 11px;
        display: block;
        width: 13px;
        height: 13px;
        line-height: 14px;
        text-align: center;
        position: absolute;
        top: 28px;
        left: 25px;
        background-color: theme-color('warning');
        border-radius: 100%;
        color: gray('white');
        font-weight: 400; //font-weight: 400;;
        overflow: hidden;
      }
    }

    &-title {
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-list {
      margin: 0;
      padding-left: 16px;
      & > :last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
}

@media screen and (min-width: breakpoint-min('md')) {
}

@media screen and (min-width: breakpoint-min('lg')) {
}
