// Button default properties
//
// C'est propriété sont permette de définir une base uniforme
// pour faire des boutons avec des balises "input", "button"
// et "a".
//
// Note du développeur :
// Oui, il va falloir sur-écrire des proprités déclaré dans
// cette mixin. Mais en même temps, l'utilisation de cette
// mixin permet d'éviter d'oublier une déclaration, ou
// de créer une différence entre les navigateurs.
//
@mixin btn-default-properties() {
  background: transparent;
  border: none;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  user-select: none;
  white-space: normal;
}

// Supprimer le margin du premier et dernier enfant
@mixin rm-child-margin() {
  @include rm-first-child-margin;
  @include rm-last-child-margin;
}

@mixin rm-first-child-margin() {
  > :first-child {
    margin-top: 0;
  }
}

@mixin rm-last-child-margin() {
  > :last-child {
    margin-bottom: 0;
  }
}
