
































































































@import '../../utilities/config';
@import '../../utilities/sass/mixins';
@import 'node_modules/bootstrap/scss/pagination';

.m-paging {
  @include rm-child-margin;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  .page-item {
    cursor: pointer;
    &.disabled {
      .page-link {
        background-color: transparent;
      }
      cursor: not-allowed;
    }
    .page-link {
      border: 0;
      background-color: transparent;
      border-radius: 0.25rem;
      &:hover {
        background-color: gray('gray-200');
      }
    }
    &.active {
      .page-link {
        background-color: theme-color('primary');
        border-color: theme-color('primary');
      }
    }
  }
}
