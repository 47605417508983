





















































































@import '../../utilities/config';

.o-documents-box {
  &__grid {
    border: 1px solid gray('gray-150');
    border-bottom: 0;
  }
  &__row {
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0;
    @media screen and (min-width: breakpoint-min('md')) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    border-bottom: 1px solid gray('gray-150');
    position: relative;
    background-color: gray('white');
    color: gray('gray-700');
    text-decoration: none;
    &:hover {
      background-color: gray('gray-100');
    }
  }
  &__col {
    padding: 0 0.75rem;
    font-size: 14px;
    min-width: 25%;

    &--download {
      min-width: calc(36px + 1.5rem);
    }
    &--title {
      width: calc(75% - (36px + 1.5rem));
      @media screen and (min-width: breakpoint-min('md')) {
        width: 100%;
      }
    }
  }
  &__doc-title {
    font-weight: 500;
    font-size: 17px;
  }
}
